import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FDN, FormButton, Option, VALIDATORS } from '@intersystems/isc-form';
import { Deployment } from 'api';
import { AbstractControl } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
@Component({
  selector: 'app-configurations-form',
  templateUrl: './configurations-form.component.html',
  styleUrls: ['./configurations-form.component.scss'],
})
export class ConfigurationsFormComponent implements OnChanges, OnDestroy {
  @Input() tenantName: string;
  @Input() deployment: Deployment;
  @Output() testFields = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();
  @Output() createInterfaceConfiguration = new EventEmitter<any>();
  @Output() createTrigger = new EventEmitter<any>();

  formModel: any = {};

  bundleOptions = [
    {
      name: 'Batch',
      value: 'batch',
    },
    {
      name: 'Collection',
      value: 'collection',
    },
    {
      name: 'Document',
      value: 'document',
    },
    {
      name: 'Transaction',
      value: 'transaction',
    },
  ];

  //FHIR Server has more limited output types
  fhirServerBundleOptions = [
    {
      name: 'Batch',
      value: 'batch',
    },
    {
      name: 'Transaction',
      value: 'transaction',
    },
  ];

  healthLakeBundleOptions = [
    {
      name: 'Collection',
      value: 'collection',
    },
    {
      name: 'Document',
      value: 'document',
    },
  ];

  healthLakeTransTypeOptions = [
    {
      name: 'Bundle',
      value: 'bundle',
    },
    {
      name: 'Individual',
      value: 'individual',
    },
    {
      name: 'Both',
      value: 'both',
    },
  ];

  bundleModeOutputOptionsSubject: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  transmissionTypeOptionsSubject: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private cdr: ChangeDetectorRef) {}

  configurationsFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'inputPropertiesHeader',
            key: 'inputPropertiesHeader',
            template: '<h3>Input Properties</h3>',
          },
          {
            id: 'CustomerSrcS3Bucket',
            key: 'CustomerSrcS3Bucket',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Input S3 Bucket is a required field',
            },
            data: {
              hint: 'The name of the Amazon S3 bucket that will supply the input data.',
            },
            templateOptions: {
              label: 'Input S3 Bucket',
              required: true,
            },
            validators: {
              ['inputLocation']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const inputlocationPattern =
                      /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/;
                    return inputlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 bucket name must be in valid format.',
              },
            },
          },
          {
            id: 'CustomerSrcS3Prefix',
            key: 'CustomerSrcS3Prefix',
            type: 'input',
            data: {
              hint: 'The prefix of the Amazon S3 bucket that will supply the input data.',
            },
            templateOptions: {
              label: 'Input S3 Prefix',
              autocomplete: 'off',
            },
            validators: {
              ['inputPrefix']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const inputlocationPattern = /^(?!\/).*[^\/]$/;
                    return inputlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 prefix must be specified without leading and trailing slash.',
              },
            },
          },
          {
            id: 'targetPropertiesHeader',
            key: 'targetPropertiesHeader',
            template: '<h3>Target Properties</h3>',
          },
          {
            id: 'targetType',
            key: 'targetType',
            type: 'select',
            templateOptions: {
              label: 'Target Type',
              required: true,
              options: [
                {
                  name: 'FHIR Server',
                  value: 'fhir_server',
                },
                {
                  name: 'Amazon HealthLake',
                  value: 'healthlake',
                },
                {
                  name: 'S3 Bucket',
                  value: 's3',
                },
              ],
            },
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Target Type is a required field',
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              onlySaveUniqueValueField: true,
              optionIdField: 'name',
            },
            hooks: {
              onInit: field =>
                field.formControl.valueChanges
                  .pipe(
                    tap(changes => this.onTargetTypeChange(changes)),
                    takeUntil(this._unsubscribeAll),
                  )
                  .subscribe(),
            },
          },
          {
            id: 'FHIRAPIKey',
            key: 'FHIRAPIKey',
            type: 'input',
            data: {
              hint: 'Replace FHIR Server API Key with the new value if set',
            },
            templateOptions: {
              label: 'New API Key',
            },
            hideExpression: model => model.targetType !== 'fhir_server',
          },
          {
            id: 'FHIRServer',
            key: 'FHIRServer',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Endpoint is a required field',
            },
            data: {
              hint: 'FHIR Server endpoint.',
            },
            templateOptions: {
              label: 'Endpoint',
              required: true,
            },
            validators: {
              ['endpoint']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    if (control.value.split('://').length > 1) {
                      control.patchValue(control.value.split('://')[1]);
                    }
                    const endpointPattern =
                      /^(?=.{1,255}$)[0-9A-Za-z](?:(?:[0-9A-Za-z]|-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|-){0,61}[0-9A-Za-z])?)*\.?$/;
                    return endpointPattern.test(control.value);
                  }
                },
                message: () => 'Endpoint name must be a valid hostname.',
              },
            },
            hideExpression: model => model.targetType !== 'fhir_server',
          },

          {
            id: 'HealthLakeDatastoreID',
            key: 'HealthLakeDatastoreID',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Amazon HealthLake ID is a required field',
            },
            data: {
              hint: `The data store identifier, which you can find in the Amazon HealthLake console
              (ex. 1f2f459836ac6c513ce899f9e4f66a59)`,
            },
            templateOptions: {
              label: 'Amazon HealthLake ID',
              required: true,
            },
            hideExpression: model => model.targetType !== 'healthlake',
          },
          {
            id: 'HealthLakeRegion',
            key: 'HealthLakeRegion',
            type: 'select',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Amazon HealthLake Region is a required field',
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              onlySaveUniqueValueField: true,
              hint: `Region of Amazon HealthLake`,
            },
            templateOptions: {
              label: 'Amazon HealthLake Region',
              required: true,
              options: [
                { name: 'us-east-1 US East (N. Virginia)', value: 'us-east-1' },
                { name: 'us-east-2 US East (Ohio)', value: 'us-east-2' },
                { name: 'us-west-1 US West (N. California)', value: 'us-west-1' },
                { name: 'us-west-2 US West (Oregon)', value: 'us-west-2' },
                { name: 'af-south-1 Africa (Cape Town)', value: 'af-south-1' },
                { name: 'ap-east-1 Asia Pacific (Hong Kong)', value: 'ap-east-1' },
                { name: 'ap-south-2 Asia Pacific (Hyderabad)', value: 'ap-south-2' },
                { name: 'ap-southeast-3 Asia Pacific (Jakarta)', value: 'ap-southeast-3' },
                { name: 'ap-southeast-5 Asia Pacific (Malaysia)', value: 'ap-southeast-5' },
                { name: 'ap-southeast-4 Asia Pacific (Melbourne)', value: 'ap-southeast-4' },
                { name: 'ap-south-1 Asia Pacific (Mumbai)', value: 'ap-south-1' },
                { name: 'ap-northeast-3 Asia Pacific (Osaka)', value: 'ap-northeast-3' },
                { name: 'ap-northeast-2 Asia Pacific (Seoul)', value: 'ap-northeast-2' },
                { name: 'ap-southeast-1 Asia Pacific (Singapore)', value: 'ap-southeast-1' },
                { name: 'ap-southeast-2 Asia Pacific (Sydney)', value: 'ap-southeast-2' },
                { name: 'ap-northeast-1 Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
                { name: 'ca-central-1 Canada (Central)', value: 'ca-central-1' },
                { name: 'ca-west-1 Canada West (Calgary)', value: 'ca-west-1' },
                { name: 'eu-central-1 Europe (Frankfurt)', value: 'eu-central-1' },
                { name: 'eu-west-1 Europe (Ireland)', value: 'eu-west-1' },
                { name: 'eu-west-2 Europe (London)', value: 'eu-west-2' },
                { name: 'eu-south-1 Europe (Milan)', value: 'eu-south-1' },
                { name: 'eu-west-3 Europe (Paris)', value: 'eu-west-3' },
                { name: 'eu-south-2 Europe (Spain)', value: 'eu-south-2' },
                { name: 'eu-north-1 Europe (Stockholm)', value: 'eu-north-1' },
                { name: 'eu-central-2 Europe (Zurich)', value: 'eu-central-2' },
                { name: 'il-central-1 Israel (Tel Aviv)', value: 'il-central-1' },
                { name: 'me-south-1 Middle East (Bahrain)', value: 'me-south-1' },
                { name: 'me-central-1 Middle East (UAE)', value: 'me-central-1' },
                { name: 'sa-east-1 South America (São Paulo)', value: 'sa-east-1' },
                { name: 'us-gov-east-1 AWS GovCloud (US-East)', value: 'us-gov-east-1' },
                { name: 'us-gov-west-1 AWS GovCloud (US-West)', value: 'us-gov-west-1' },
              ],
            },
            hideExpression: model => model.targetType !== 'healthlake',
          },
          {
            id: 'TransmissionType',
            key: 'TransmissionType',
            type: 'select',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Transmission Type is a required field',
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              onlySaveUniqueValueField: true,
              hint: `How FHIR resources will be stored in Amazon HealthLake`,
            },
            templateOptions: {
              label: 'Transmission Type',
              required: true,
              options: [
                {
                  name: 'Bundle',
                  value: 'bundle',
                },
                {
                  name: 'Individual',
                  value: 'individual',
                },
                {
                  name: 'Both',
                  value: 'both',
                },
              ],
            },
            hideExpression: model => model.targetType !== 'healthlake',
          },
          {
            id: 'HealthLakeAssumeRole',
            key: 'HealthLakeAssumeRole',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Amazon HealthLake Assume Role ARN is a required field',
            },
            data: {
              hint: 'ARN for role that FTS will assume for HealthLake access',
            },
            templateOptions: {
              label: 'Amazon HealthLake Role',
              required: true,
            },
            hideExpression: model => model.targetType !== 'healthlake',
          },
          {
            id: 'HealthLakeExternalID',
            key: 'HealthLakeExternalID',
            type: 'input',
            data: {
              hint: `This External ID is entered into AWS when the role is set up.`,
            },
            templateOptions: {
              label: 'Amazon Healthlake Role External ID',
              required: true,
            },
            hideExpression: model => (model.targetType ? model.targetType !== 'healthlake' : true),
          },
          {
            id: 'HealthLakeSessionName',
            key: 'HealthLakeSessionName',
            type: 'input',
            data: {
              hint: `This Session Name is used for AWS logging during HealthLake transactions.`,
            },
            templateOptions: {
              label: 'Amazon Healthlake Session Name',
              required: true,
            },
            hideExpression: model => model.targetType !== 'healthlake',
          },
          {
            id: 'CustomerDestS3Bucket',
            key: 'CustomerDestS3Bucket',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Target Location is a required field',
            },
            data: {
              hint: 'The location of the Amazon S3 bucket that will store the transformed data.',
            },
            templateOptions: {
              label: 'Target S3 Bucket',
              required: true,
            },
            validators: {
              ['targetLocation']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const targetlocationPattern =
                      /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/;
                    return targetlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 bucket must be in valid format.',
              },
            },
            hideExpression: model => model.targetType !== 's3',
          },

          {
            id: 'CustomerDestS3Prefix',
            key: 'CustomerDestS3Prefix',
            type: 'input',
            data: {
              hint: 'The prefix of the Amazon S3 bucket that will store the target data.',
            },
            templateOptions: {
              label: 'Target S3 Prefix',
              autocomplete: 'off',
            },
            validators: {
              ['inputPrefix']: {
                expression: (control: AbstractControl) => {
                  if (control.value) {
                    const inputlocationPattern = /^(?!\/).*[^\/]$/;
                    return inputlocationPattern.test(control.value);
                  }
                },
                message: () => 'S3 prefix must be specified without leading and trailing slash.',
              },
            },
            hideExpression: model => (model.targetType ? model.targetType !== 's3' : true),
          },

          {
            id: 'BundleMode',
            key: 'BundleMode',
            type: 'select',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Output Mode is a required field',
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              onlySaveUniqueValueField: true,
              hint: `Output Mode`,
            },
            templateOptions: {
              label: 'Output Mode',
              required: true,
              options: this.bundleOptions,
              asyncOptions: () => this.bundleModeOutputOptionsSubject.asObservable(),
            },
            hideExpression: model =>
              model.targetType
                ? model.targetType !== 'fhir_server' && model.targetType !== 's3' && model.targetType !== 'healthlake'
                : true,
          },
          {
            id: 'ErrorBehavior',
            key: 'ErrorBehavior',
            type: 'select',
            templateOptions: {
              label: 'Error Behavior',
              required: true,
              options: [
                {
                  name: 'Ignore',
                  value: 'ignore',
                },
                {
                  name: 'Pause',
                  value: 'pause',
                },
              ],
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              onlySaveUniqueValueField: true,
            },
          },
          {
            id: 'Archive',
            key: 'Archive',
            type: 'select',
            templateOptions: {
              label: 'Archive Strategy',
              required: true,
              options: [
                {
                  name: 'Delete',
                  value: 'delete',
                },
                {
                  name: 'Ignore',
                  value: 'ignore',
                },
                {
                  name: 'Move',
                  value: 'move',
                },
              ],
            },
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              onlySaveUniqueValueField: true,
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      id: 'btn-activate-trigger',
      text: 'Activate Trigger',
      buttonClass: 'secondary',
      type: 'submit',
      disabled: (_formModel, _formOptions, form) => form.valueChanges.pipe(map(() => !form.pristine)),
      callback: (_event, _button, formModel) => this.createTrigger.emit(formModel),
    },
    {
      id: 'btn-cancel',
      text: 'Cancel',
      buttonClass: 'secondary',
      type: 'reset',
      callback: () => null,
    },
    {
      id: 'btn-save',
      text: 'Save',
      buttonClass: 'primary',
      type: 'submit',
      disabledIfFormInvalid: true,
      callback: (_event, _button, formModel) => this.createInterfaceConfiguration.emit(formModel),
    },
  ];

  ngOnChanges(change) {
    if (change.deployment.currentValue) {
      this.formModel = { ...change.deployment.currentValue.configuration };
      if (!this.formModel.ErrorBehavior) this.formModel.ErrorBehavior = 'ignore';
      if (!this.formModel.Archive) this.formModel.Archive = 'delete';
      if (this.formModel.CustomerDestS3Bucket) this.formModel.targetType = 's3';
      if (this.formModel.HealthLakeRegion) this.formModel.targetType = 'healthlake';
      if (this.formModel.FHIRServer) this.formModel.targetType = 'fhir_server';
      this.onTargetTypeChange(this.formModel.targetType);
    }
  }

  getJsonExample(): any {
    const jsonExample = {
      Version: '2012-10-17',
      Statement: [
        {
          Sid: 'Allow S3 access for FTS',
          Effect: 'Allow',
          Principal: {
            AWS: [
              'arn:aws:iam::' + this.deployment.account + ':role/' + this.deployment.deploymentid + '-lambdaRole',
              'arn:aws:iam::' +
                this.deployment.account +
                ':role/transform-' +
                this.deployment.deploymentid +
                '-taskRole',
            ],
          },
          Action: ['s3:GetObject', 's3:PutObject', 's3:DeleteObject'],
          Resource: 'arn:aws:s3:::' + this.formModel.CustomerSrcS3Bucket + '/*',
        },
        {
          Sid: 'Allow S3 notifications',
          Effect: 'Allow',
          Principal: {
            AWS: 'arn:aws:iam::' + this.deployment.account + ':role/' + this.deployment.deploymentid + '-lambdaRole',
          },
          Action: ['s3:GetBucketNotification', 's3:PutBucketNotification'],
          Resource: 'arn:aws:s3:::' + this.formModel.CustomerSrcS3Bucket + '',
        },
      ],
    };
    return jsonExample;
  }

  getJsonExampleDest(): any {
    const jsonExample = {
      Version: '2012-10-17',
      Statement: [
        {
          Sid: 'Allow S3 access for FTS',
          Effect: 'Allow',
          Principal: {
            AWS: 'arn:aws:iam::' + this.deployment.account + ':role/' + this.deployment.deploymentid + '-lambdaRole',
          },
          Action: ['s3:GetObject', 's3:PutObject', 's3:DeleteObject'],
          Resource: 'arn:aws:s3:::' + this.formModel.CustomerDestS3Bucket + '/*',
        },
      ],
    };
    return jsonExample;
  }

  onTargetTypeChange(value: string): void {
    if (value === 'fhir_server') {
      // Only valid Outputs for FHIR Server are Batch and Transaction
      this.bundleModeOutputOptionsSubject.next(this.fhirServerBundleOptions);
    } else if (value === 's3') {
      this.bundleModeOutputOptionsSubject.next(this.bundleOptions);
    } else if (value === 'healthlake') {
      this.bundleModeOutputOptionsSubject.next(this.healthLakeBundleOptions);
      this.transmissionTypeOptionsSubject.next(this.healthLakeTransTypeOptions);
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
